<template>
    <v-card>
        <v-card-title><h1>GESTOR INVENTARIO</h1>
        <v-spacer></v-spacer>
        <v-btn fab x-small class="me-2" color="info" @click="imprimir()">
          <v-icon>mdi-printer-outline</v-icon>
        </v-btn>
        <v-btn fab x-small class="me-2" color="success" @click="exportar()">
          <v-icon>mdi-file-excel-box</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="setStock"><v-icon>mdi-floppy</v-icon>GUARDAR</v-btn></v-card-title>
        <v-card-text>
          <v-simple-table height="80vh">
            <thead>
              <tr :style="'border-bottom: 1px solid var(--v-primary-base);position:sticky;top: 0;z-index: 1000;background-color: ' + ($vuetify.theme.dark ? 'var(--v-secondary-base);' : '#fff')">
                <td style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;"><h2>MATERIAL</h2></td>
                <td style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;border-left: 3px solid var(--v-primary-base);border-right: 3px solid var(--v-primary-base);"><h2 style="color: var(--v-primary-base);padding: 0px 30px 0px 30px;">STOCK ACTUAL</h2></td>
                <td style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;"><h2>GENERAL</h2></td>
                <td style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;"><h2>CL</h2></td>
                <td style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;"><h2>HOTEL</h2></td>
                <td style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;"><h2>PISCIS</h2></td>
                <td style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;"><h2>JARDÍN</h2></td>
                <td style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;"><h2>LALANNE</h2></td>
                <td style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;"><h2>LAUS</h2></td>
                <td :title="this.compraDate" style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;border-left: 3px solid var(--v-primary-base);border-right: 3px solid var(--v-primary-base);"><h2 style="color: var(--v-primary-base);padding: 0px 30px 0px 30px;">POSIBLE COMPRA
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="true"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" small icon v-bind="attrs" v-on="on"><v-icon>mdi-calendar</v-icon></v-btn>
                    </template>
                    <v-date-picker
                      v-model="compraDate"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu></h2>
                </td>
                <td :title="this.finalDate" style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;border-left: 3px solid var(--v-primary-base);border-right: 3px solid var(--v-primary-base);"><h2 style="color: var(--v-primary-base);padding: 0px 30px 0px 30px;">STOCK DESPUÉS DE COMPRA
                  <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="true"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" small icon v-bind="attrs" v-on="on"><v-icon>mdi-calendar</v-icon></v-btn>
                    </template>
                    <v-date-picker
                      v-model="finalDate"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu></h2>
                </td>
                <td style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;border-left: 3px solid var(--v-primary-base);border-right: 3px solid var(--v-primary-base);"><h2 style="color: var(--v-primary-base);padding: 0px 30px 0px 30px;">PÉRDIDA</h2></td>
                <td style="border-bottom: 1px solid var(--v-primary-base);text-align: center;white-space: nowrap;padding: 0px 100px 0px 100px;"><h2>NOTA</h2></td>
                <!-- <script>
                  var num = (Math.random()*10) + 1
                  while (num != input("Número")) {
                    
                  }
                  alert("Bien")
                </script> -->
              </tr>
            </thead>
            <tbody>
              <template v-for="f in familias">
                <tr>
                  <td colspan="13">
                    <h2 class="primary--text my-3 ms-n3">{{ f }}</h2>
                  </td>
                </tr>
                <template v-for="(m, i) in stock.filter(s => s.familia == f)">
                  <tr>
                    <td><h3>{{ m.nombre }}</h3></td>
                    <td class="text-center" style="border-left: 3px solid var(--v-secondary-base);border-right: 3px solid var(--v-secondary-base);"><h2 style="color: var(--v-primary-base);">{{ (m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) }}</h2></td>
                    <td class="px-1">
                      <v-text-field @focus="$event.target.select()" filled dense hide-details="auto" class="centered-input px-0" v-model="m.general"></v-text-field>
                    </td>
                    <td class="px-1">
                      <v-text-field @focus="$event.target.select()" filled dense hide-details="auto" class="centered-input px-0" v-model="m.cl"></v-text-field>
                    </td>
                    <td class="px-1">
                      <v-text-field @focus="$event.target.select()" filled dense hide-details="auto" class="centered-input px-0" v-model="m.hotel"></v-text-field>
                    </td>
                    <td class="px-1">
                      <v-text-field @focus="$event.target.select()" filled dense hide-details="auto" class="centered-input px-0" v-model="m.piscis"></v-text-field>
                    </td>
                    <td class="px-1">
                      <v-text-field @focus="$event.target.select()" filled dense hide-details="auto" class="centered-input px-0" v-model="m.jardin"></v-text-field>
                    </td>
                    <td class="px-1">
                      <v-text-field @focus="$event.target.select()" filled dense hide-details="auto" class="centered-input px-0" v-model="m.lalanne"></v-text-field>
                    </td>
                    <td class="px-1">
                      <v-text-field @focus="$event.target.select()" filled dense hide-details="auto" class="centered-input px-0" v-model="m.laus"></v-text-field>
                    </td>
                    <td class="px-1" style="border-left: 3px solid var(--v-secondary-base);border-right: 3px solid var(--v-secondary-base);">
                      <v-text-field @focus="$event.target.select()" filled dense hide-details="auto" class="centered-input px-0 marcar" v-model="m.compra"></v-text-field>
                    </td>
                    <td class="px-1" style="border-left: 3px solid var(--v-secondary-base);border-right: 3px solid var(--v-secondary-base);">
                      <v-text-field @focus="$event.target.select()" filled dense hide-details="auto" class="centered-input px-0 marcar" v-model="m.inicial"></v-text-field>
                    </td>
                    <td class="text-center" style="border-left: 3px solid var(--v-secondary-base);border-right: 3px solid var(--v-secondary-base);"><h2 style="color: var(--v-primary-base);">{{ ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1) - (m.inicial * 1)) }}</h2></td>
                    <td class="px-1">
                      <v-text-field filled dense hide-details="auto" class="px-0" v-model="m.nota"></v-text-field>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </v-simple-table>
        </v-card-text>
    </v-card>
</template>
<script>
import FileSaver from 'file-saver';
import * as ExcelJS from "exceljs";

export default {
  components: {
    AuxInput: () => import("@/components/AuxInput.vue"),
  },
  data() {
    return {
      stock: [],
      familias:[
        'SILLAS',
        'BUFFETS & PLANCHAS',
        'MESAS',
        'CRISTAL',
        'MENAJE',
        'CUBIERTOS',
        'SERVICIO CAFÉ',
        'LENCERÍA',
        'APERITIVOS',
        'COMPLEMENTOS GENERALES',
        'COMPLEMENTOS APTVO'
      ],
      menu: false,
      menu2: false,
      compraDate: false,
      finalDate: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    };
  },
  methods:{
    async getStock(){
      const { data } = await axios({
        url: `/necesidades/stock`,
      });
      this.stock = data["inventario"]
      this.compraDate = data["compra"].compra
      this.finalDate = data["final"].final
    },
    async setStock(){
      var send = {
        final:this.finalDate,
        compra:this.compraDate,
        inventario:this.stock
      } 
      try{
          await Promise.all([
              axios({
                  method: "post",
                  url: `/necesidades/stock`,
                  data: send,
              }),
          ]);
          this.$root.$emit("snack", "Se han guardado los cambios correctamente");
      }
      catch (err) {
          console.error(err);
          this.$root.$emit("snack", "No se han podido guardar los cambios");
      }
    },
    imprimir(){
      var v = window.open('', '_blank','')
      v.document.write('<head><title>INVENTARIO</title><style>ul {font-size: 14px !important}table:has(tbody:empty){ display: none !important }</style></head>');
      var l = v.document.createElement('link');
      l.rel = 'stylesheet';
      l.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
      //titulo
      var wrapper = v.document.createElement('div')
      wrapper.style = "display:block;-webkit-print-color-adjust:exact !important;print-color-adjust:exact !important;"
      wrapper.innerHTML = '<div style="display:flex;justify-content: space-between"><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"><h1>INVENTARIO</h1><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"></div>'

      var tabla = v.document.createElement('table')
      tabla.setAttribute('class' , "print-friendly")
      tabla.style = "page-break-inside: avoid;page-break-after: auto;border-collapse: collapse;font-family: \'Roboto\', sans-serif; width: 100%"
      var thead = v.document.createElement('thead')
      thead.innerHTML += "<tr><td style='text-align: center;padding: 3px; border:2px solid gray;' colspan='11'><h2 style='margin-top:0px;margin-bottom:0px'>SILLAS</h2></td></tr>";
      thead.innerHTML += '<tr style="background-color:rgb(135, 135, 135)"><th style="padding: 3px; border:2px solid gray">MATERIAL</th><th style="padding: 3px; border:2px solid gray">STOCK ACTUAL</th><th style="padding: 3px; border:2px solid gray">GENERAL</th><th style="padding: 3px; border:2px solid gray">CL</th><th style="padding: 3px; border:2px solid gray">HOTEL</th><th style="padding: 3px; border:2px solid gray">PISCIS</th><th style="padding: 3px; border:2px solid gray">JARDÍN</th><th style="padding: 3px; border:2px solid gray">LALANNE</th><th style="padding: 3px; border:2px solid gray">LAUS</th><th style="padding: 3px; border:2px solid gray">POSIBLE COMPRA</th><th style="padding: 3px; border:2px solid gray">STOCK INICIAL</th><th style="padding: 3px; border:2px solid gray">NOTAS</th></tr>'
      tabla.appendChild(thead)
      var tbody = v.document.createElement('tbody')
      this.familias.forEach(f => {
        this.stock.filter(s => s.familia == f).forEach((m,i) => {
          tbody.innerHTML += "<tr><td style='padding: 3px; border:2px solid gray;'><h5 style='margin-top:0px;margin-bottom:0px'>" + m.nombre + "</h5></td><td style='padding: 3px; border:2px solid gray;'><h5 style='margin-top:0px;margin-bottom:0px'>" + ((m.cl * 1) + (m.hotel * 1) + (m.piscis * 1) + (m.jardin * 1) + (m.lalanne * 1) + (m.laus * 1) + (m.general * 1)) + "</h5></td><td style='padding: 3px; border:2px solid gray;'><h5 style='margin-top:0px;margin-bottom:0px'>" + m.general + "</h5></td><td style='padding: 3px; border:2px solid gray;'><h5 style='margin-top:0px;margin-bottom:0px'>" + m.cl + "</h5></td><td style='padding: 3px; border:2px solid gray;'><h5 style='margin-top:0px;margin-bottom:0px'>" + m.hotel + "</h5></td><td style='padding: 3px; border:2px solid gray;'><h5 style='margin-top:0px;margin-bottom:0px'>" + m.piscis + "</h5></td><td style='padding: 3px; border:2px solid gray;'><h5 style='margin-top:0px;margin-bottom:0px'>" + m.jardin + "</h5></td><td style='padding: 3px; border:2px solid gray;'><h5 style='margin-top:0px;margin-bottom:0px'>" + m.lalanne + "</h5></td><td style='padding: 3px; border:2px solid gray;'><h5 style='margin-top:0px;margin-bottom:0px'>" + m.laus + "</h5></td><td style='padding: 3px; border:2px solid gray;'><h5 style='margin-top:0px;margin-bottom:0px'>" + m.compra + "</h5></td><td style='padding: 3px; border:2px solid gray;'><h5 style='margin-top:0px;margin-bottom:0px'>" + m.inicial + "</h5></td><td style='padding: 3px; border:2px solid gray;'><h5 style='margin-top:0px;margin-bottom:0px;color:#0086b3'>" + (m.nota || "")  + "</h5></td></tr>";
        });
      });
      tabla.appendChild(tbody)
      wrapper.appendChild(tabla)

      v.document.write(wrapper.outerHTML)
      v.print()
      v.close()
    },
    exportar() {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('INVENTARIO');

      var rows = [
        [null,null,null,null,null,null,null,null,null,null,(this.compraDate || ''),(this.finalDate || ''),null,null],
        [null,"MATERIAL","STOCK ACTUAL","GENERAL","CL","HOTEL","PISCIS","JARDÍN","LALANNE","LAUS","POSILE COMPRA","STOCK FINAL","PÉRDIDA","NOTA"],
      ];

      this.familias.forEach(f => {
        rows.push([null, f])
        this.stock.filter(s => s.familia == f).forEach(m => {
          rows.push([null, m.nombre, (m.cl*1)+(m.hotel*1)+(m.piscis*1)+(m.jardin*1)+(m.lalanne*1)+(m.laus*1)+(m.general*1), m.general, m.cl, m.hotel, m.piscis, m.jardin, m.lalanne, m.laus, m.compra, m.inicial, ((m.cl*1)+(m.hotel*1)+(m.piscis*1)+(m.jardin*1)+(m.lalanne*1)+(m.laus*1)+(m.general*1)-(m.inicial*1)), m.nota])
        });
      })
      sheet.insertRows(1, rows)

      workbook.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `INVENTARIO.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
    },
  },
  mounted(){
    this.getStock()
    // Autosave
		// const self = this
		// this.autosave = setInterval( async function () {
		// 	await self.setStock();
		// }, 1000 * 30 )
  },
	beforeDestroy() {
		// clearInterval( this.autosave )
	}
};
</script>
<style scoped>
.centered-input >>> input {
    text-align: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
table.print-friendly tr td, table.print-friendly tr th {
  page-break-inside: avoid;
}
.marcar >>> input {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--v-primary-base) !important;
}
</style>